import { size } from "lodash";
import React, { useEffect, useState } from "react";
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

export const OptionalInput = ({ optionalInputs, setOptionalInputs, name }) => {
  const [switchInput, setSwitchInput] = useState(false);

  useEffect(() => {

    if(size(optionalInputs)>0){
      optionalInputs.listOptionalInput.forEach(field => {
        if(field===name){
          setSwitchInput(true);
        }
      });
    }
  }, [name,optionalInputs]);

  const changeSwitchInput = () => {
    setSwitchInput(!switchInput);
    if (!switchInput) {
      setOptionalInputs({
        change:true,
        listOptionalInput: [...optionalInputs.listOptionalInput, name],
      });
    } else {
      setOptionalInputs({
        change:true,
        listOptionalInput:optionalInputs.listOptionalInput.filter(input=>input!==name),
      });
    }
  };

  return (
    <div className="edit__switch" onClick={changeSwitchInput}>
      {!switchInput ? (
        <EyeFill color="red" className="edit__icon-switch"/>
      ) : (
        <EyeSlashFill color="red" className="edit__icon-switch" />
      )}
      <p className="edit__text-switch">{switchInput ? "Inactivo" : "Activo"}</p>
    </div>
  );
};
