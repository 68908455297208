import React from "react";
import { ItemInformation } from "../information/ItemInformation";

export const InfoAdicional = ({ infoCode, checkFields }) => {

  return (
    <div className="view__div-aditional-information animate__animated animate__fadeIn">
      <div className="view__container-aditional-information">
        {checkFields.doctorName && (
          <ItemInformation
            label="Nombre médico"
            data={infoCode.doctorName}
            classInformation="view__div-information-aditional"
          />
        )}
        {checkFields.doctorPhone && (
          <ItemInformation
            label="Teléfono médico"
            data={infoCode.doctorPhone}
            classInformation="view__div-information-aditional"
          />
        )}
        {checkFields.EPS && (
          <ItemInformation
            label="EPS"
            data={checkFields.EPS ? infoCode.EPS : ""}
            classInformation="view__div-information-aditional"
          />
        )}
        {checkFields.prepaidMedicine && (
          <ItemInformation
            label="Medicina prepagada"
            data={infoCode.prepaidMedicine}
            classInformation="view__div-information-aditional"
          />
        )}
        {/* {checkFields.vaccineCovid && (
          <ItemInformation
            label="Vacuna covid"
            data={nameVaccineCovid}
            classInformation="view__div-information-aditional"
          />
        )} */}
      </div>
      <div className="view__container-diseases-allergies">
        {checkFields.diseases && (
          <div className="view__div-diseases-allergies">
            <div>
              <h4 className="view__title-information">Enfermedades</h4>
              <p className="view__text-information">{infoCode.diseases}</p>
            </div>
          </div>
        )}
        {checkFields.allergies && (
          <div className="view__div-diseases-allergies">
            <div>
              <h4 className="view__title-information">Es alérgico a:</h4>
              <p className="view__text-information">{infoCode.allergies}</p>
            </div>
          </div>
        )}
      </div>
      {/* {checkFields.photoVaccine && (
        <div className="edit__button-vaccine" onClick={openModal}>
          <div className="edit__div-button-avatar">Ver vacuna</div>
        </div>
      )} */}
      {/* <ModalPhoto url={urlPhoto} handleClose={handleClose} show={show} /> */}
    </div>
  );
};
