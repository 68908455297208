import React from "react";
import { ModalPhoto } from "../modal/ModalPhoto";
import { ItemInformation } from "../information/ItemInformation";
import { AlertWithoutButton } from "../alert/Alert";
import { useState } from "react";

export const InfoVaccine = ({ infoCode, checkFields }) => {
  let urlPhoto = "";
  let nameVaccineCovid = "";

  console.log(infoCode);

  if (infoCode.linkPhotoVaccine === undefined) {
    urlPhoto = "";
  } else {
    urlPhoto = infoCode.linkPhotoVaccine;
  }
  if (infoCode.nameVaccineCovid === undefined) {
    nameVaccineCovid = "";
  } else {
    nameVaccineCovid = infoCode.nameVaccineCovid;
  }

  const [show, setShow] = useState(false);

  const openModal = () => {
    if (checkFields.photoVaccine) {
      setShow(true);
    } else {
      AlertWithoutButton("error", "Información no disponible");
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="view__div-aditional-information animate__animated animate__fadeIn">
      <div className="view__container-aditional-information">
        {checkFields.vaccineCovid &&
          <ItemInformation
            label="Vacuna covid-19"
            data={nameVaccineCovid}
            classInformation="view__div-information-aditional"
          />}
      </div>
      {checkFields.photoVaccine &&
        <div className="edit__button-vaccine" onClick={openModal}>
          <div className="edit__div-button-avatar">Ver vacuna</div>
        </div>}
      <ModalPhoto url={urlPhoto} handleClose={handleClose} show={show} />
    </div>
  );
};
