import { size } from 'lodash';

export function validateFacebook(url) {
  let result = '';
  if (size(url) >= 17 && url.substring(0, 17) === 'www.facebook.com/') {
    result = `https://${url}`;
  } else if (
    size(url) >= 25 &&
    url.substring(0, 25) === 'https://www.facebook.com/'
  ) {
    result = url;
  } else if (size(url) > 0) {
    result = `https://www.facebook.com/${url}`;
  }
  return result;
}

export function validateInstagram(url) {
  let result = '';
  if (size(url) >= 18 && url.substring(0, 18) === 'www.instagram.com/') {
    result = `https://${url}`;
  } else if (
    (size(url) >= 26 &&
      url.substring(0, 26) === 'https://www.instagram.com/') ||
    (size(url) >= 22 && url.substring(0, 22) === 'https://instagram.com/')
  ) {
    result = url;
  } else if (size(url) > 0 && result !== '') {
    result = `https://www.instagram.com/${url}`;
  }
  return result;
}

// export function validateTiktok(url) {
//   let result = '';
//   if (size(url) >= 16 && url.substring(0, 15) === 'www.tiktok.com/') {
//     result = `https://${url}`;
//   } else if (
//     size(url) >= 26 &&
//     url.substring(0, 23) === 'https://www.tiktok.com/'
//   ) {
//     result = url;
//   } else if (size(url) > 0) {
//     result = `https://www.tiktok.com/@${url}`;
//   }
//   return result;
// }

export function validateLinkedIn(url) {
  let result = '';
  if (size(url) >= 17 && url.substring(0, 17) === 'www.linkedin.com/') {
    result = `https://${url}`;
  } else if (
    size(url) >= 25 &&
    url.substring(0, 25) === 'https://www.linkedin.com/'
  ) {
    result = url;
  }
  return result;
}
