import React from 'react';
import { useParams } from "react-router-dom";

export const ScreenResponse = () => {

  let { type } = useParams();

  return (
    <>
      <div className="index__header-body">
        <h1 className="index__header-text">Personal-id</h1>
      </div>
      {type === "screenEdit" &&(
      <div>
        <div className="index__container-icon">
          <span style={{ color: "green" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </span>
        </div>
        <p className="index__text-description">
          Gracias por registrar la información
        </p>
      </div>
      )}
      {type === "suscription" &&(
        <h5 className="response__text-description-suscription">Tu suscripción ha finalizado debe renovar nuevamente el servicio de VCARDS</h5>
      )}
      {type === "noTerms" &&(
        <h5 className="response__text-description-suscription">Te invitamos a escanear de nuevo el código QR y darle acepto para que puedas registrar la información y disfrutar de tu manilla</h5>
      )}
    </>
  );
};
