
export const validateForm = (state)=>{

    let isError = [{name:''}, {name:''}, {name:''}, {name:''}, {name:''},{name:''}];

    if(state.name === ''){
        isError[0]={
            name:'name'
        };
    }
    if(state.lastName === ''){
        isError[1]={
            name:'lastName'
        };
    }
    if(state.phone === ''){
        isError[2]={
            name:'phone'
        };
    }
    if(state.city === ''){
        isError[3]={
            name:'city'
        };
    }
    if(state.email === ''){
        isError[4]={
            name:'email'
        };
    }
    // if(state.emailContact === ''){
    //     isError[5]={
    //         name:'emailContact'
    //     };
    // }
    
    return isError;

}