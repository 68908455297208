import { urlProduction } from "../utils/constants";

export async function saveInitialPlan(infoSuscription, id){

    let result = false;
  
    const requestOptions = {
      method: "POST",
      body:JSON.stringify(infoSuscription),
  
    };
  
    await fetch(
      `${urlProduction}/api/codes/saveInitialPlan?id=${id}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        result = response;
      });
  
    return result;
  
  }

  export async function getInfoPlan(idDoc) {

    let infoPlan = {};
  
    await fetch(
      `${urlProduction}/api/codes/getInfoPlan/?id=${idDoc}`
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        infoPlan = response;
      })
  
    return infoPlan;
  }