import React, { useEffect, useState } from "react";
import moment from "moment";
import { EyeFill, Fullscreen } from "react-bootstrap-icons";
import { useForm } from "../../hooks/useForm";
import { validateForm } from "../../utils/validateForm";
import { Button } from "../button/Button";
import { Input } from "../inputs/Input";
import { Label } from "../inputs/Label";
import { Select } from "../inputs/Select";
import { SelectIndicative } from "../inputs/SelectIndicative";
import { TextArea } from "../inputs/TextArea";
import { saveInformationCode, downloadVirtualCard } from "../../api/apiCode";
import { AlertWithoutButton } from "../alert/Alert";
import { saveInitialPlan } from "../../api/apiSuscription";
import { Avatar } from "../avatar/Avatar";
import { saveImageCloud } from "../../helpers/SaveImageStorage";
import { OptionalInput } from "./OptionalInput";
import { addOptionalField } from "../../api/apiParameter";
import { ModalPhoto } from "../modal/ModalPhoto";

export const Form = ({
  infoCountryCode,
  infobloodType,
  idDoc,
  infoCode,
  optionalInputs,
  setOptionalInputs,
  codeEdit
}) => {
  const [values, changeInput] = useForm(infoCode);
  const [fieldRequired, setFieldRequired] = useState([
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
    { name: "" }
  ]);
  const [textButton, setTextButton] = useState("Guardar");
  const [loading, setLoading] = useState({
    save: false,
    vaccine: false,
    vaccine1: false
  });
  const [indicative, setIndicative] = useState("+57");
  const [infoFile, setInfoFile] = useState("");
  const [infoFile1, setInfoFile1] = useState("");
  const [urlAvatar, setUrlAvatar] = useState("");
  const [urlAvatar1, setUrlAvatar1] = useState("");
  const [textButtonPhoto, setTextButtonPhoto] = useState("Cargar foto");
  const [textButtonPhoto2, setTextButtonPhoto2] = useState("Cargar foto");
  const [buttonVcard, setButtonVcard] = useState("Descargar tarjeta virtual");
  const [show, setShow] = useState(false);
  const [listUrlImage, setListUrlImage] = useState([]);
  const [imageModal, setImageModal] = useState("");
  const [isDowloading, setIsDowloading] = useState(false);

  const openModal = id => {
    if (id === 0) {
      if (urlAvatar !== "") {
        setImageModal(urlAvatar);
      } else if (infoCode.linkPhotoVaccine[0]) {
        setImageModal(infoCode.linkPhotoVaccine[0]);
      } else {
        setImageModal("");
      }
    } else {
      if (urlAvatar1 !== "") {
        setImageModal(urlAvatar1);
      } else if (infoCode.linkPhotoVaccine[1]) {
        setImageModal(infoCode.linkPhotoVaccine[1]);
      } else {
        setImageModal("");
      }
    }
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(
    () => {
      if (loading.vaccine) {
        setTextButtonPhoto("Cargando");
      } else if (loading.vaccine1) {
        setTextButtonPhoto2("Cargando");
      } else if (infoCode["linkPhoto"] !== "") {
        setTextButtonPhoto("Editar frontal");
        setTextButtonPhoto2("Editar respaldo");
      } else {
        setTextButtonPhoto("Cargar frontal");
        setTextButtonPhoto2("Cargar respaldo");
      }
    },
    [loading, setTextButtonPhoto, infoCode]
  );

  const [infoSuscription, setInfoSuscription] = useState({
    status: "APPROVED",
    startDate: moment().format("DD/MM/YYYY HH:mm:ss"),
    dueDate: moment().add("1", "y").format("DD/MM/YYYY HH:mm:ss"),
    sourceCode: "",
    idPlan: "1",
    lastNumberCard: ""
  });

  useEffect(
    () => {
      if (infoFile !== "") {
        setLoading({ save: false, vaccine: true, vaccine1: false });
        saveImageCloud(
          idDoc,
          infoFile,
          "vaccineUser",
          "photoVaccine",
          setLoading,
          setInfoFile,
          listUrlImage,
          setListUrlImage,
          infoCode["linkPhotoVaccine"][0]
            ? infoCode["linkPhotoVaccine"][0]
            : "",
          infoCode["linkPhotoVaccine"][1] ? infoCode["linkPhotoVaccine"][1] : ""
        )
          .then(response => {
            setUrlAvatar(response);
          })
          .catch(() => {
            AlertWithoutButton("error", "004: Error actualizando la foto");
          });
      }
    },
    [infoFile, idDoc, infoCode, listUrlImage]
  );

  useEffect(
    () => {
      if (infoFile1 !== "") {
        setLoading({ save: false, vaccine: false, vaccine1: true });
        saveImageCloud(
          idDoc,
          infoFile1,
          "vaccineUser1",
          "photoVaccine",
          setLoading,
          setInfoFile1,
          listUrlImage,
          setListUrlImage,
          infoCode["linkPhotoVaccine"][0]
            ? infoCode["linkPhotoVaccine"][0]
            : "",
          infoCode["linkPhotoVaccine"][1] ? infoCode["linkPhotoVaccine"][1] : ""
        )
          .then(response => {
            setUrlAvatar1(response);
          })
          .catch(() => {
            AlertWithoutButton("error", "004: Error actualizando la foto");
          });
      }
    },
    [infoFile1, idDoc, listUrlImage, infoCode]
  );

  useEffect(
    () => {
      const target = {
        name: "indicative",
        value: indicative
      };
      changeInput({ target });
    },
    [indicative]
  );

  useEffect(
    () => {
      if (optionalInputs.change) {
        saveOptionalField();
      }
    },
    [optionalInputs]
  );

  useEffect(
    () => {
      if (loading.save) {
        setTextButton("Guardando");
      } else {
        setTextButton("Guardar");
      }
    },
    [loading, setTextButton]
  );

  const saveInfoCode = async e => {
    e.preventDefault();
    const missing = validateForm(values);
    setFieldRequired(missing);
    if (
      missing[0].name !== "" ||
      missing[1].name !== "" ||
      missing[2].name !== "" ||
      missing[3].name !== "" ||
      missing[4].name !== "" ||
      missing[5].name !== ""
    ) {
      AlertWithoutButton("error", "Error. Los campos con [*] son requeridos");
    } else {
      setLoading({ save: true, vaccine: false, vaccine1: false });
      let result = false;
      let resultFinal = false;
      await saveInformationCode(values, idDoc)
        .then(response => {
          result = response;
        })
        .catch(() => {
          setLoading({ save: false, vaccine: false, vaccine1: false });
        });

      if (result && infoCode.dateEdit === "") {
        await saveInitialPlan(infoSuscription, idDoc)
          .then(response => {
            resultFinal = response;
          })
          .catch(() => {
            setLoading({ save: false, vaccine: false, vaccine1: false });
            AlertWithoutButton("error", "004: Error actualizando los datos");
          });
      } else {
        setLoading({ save: false, vaccine: false, vaccine1: false });
        AlertWithoutButton("error", "004: Error actualizando los datos");
      }

      if (resultFinal || infoCode.dateEdit !== "") {
        setLoading({ save: false, vaccine: false, vaccine1: false });
        AlertWithoutButton("success", "Datos guardados exitosamente");
        setTimeout(() => {
          window.location.href = `/web/response/edit/screenEdit`;
        }, 1500);
      } else {
        setLoading({ save: false, vaccine: false, vaccine1: false });
        AlertWithoutButton("error", "004: Error actualizando los datos");
      }
    }
  };

  const saveOptionalField = async () => {
    await addOptionalField(optionalInputs.listOptionalInput, idDoc)
      .then(response => {
        AlertWithoutButton(
          "success",
          "Campo opcional actualizado exitosamente"
        );
      })
      .catch(() => {
        setLoading(false);
        AlertWithoutButton(
          "error",
          "005: Error actualizando los campos opcionales"
        );
      });
  };

  const openFile = number => {
    if (number === 1) {
      document.querySelector("#fileSelectorVaccine").click();
    } else {
      document.querySelector("#fileSelectorVaccine1").click();
    }
  };

  const eventInputFile = async e => {
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image.jpg"
    ) {
      setInfoFile(file);
    } else {
      AlertWithoutButton(
        "error",
        "003: Error. selecciona un formato de imagen valido"
      );
    }
  };

  const eventInputFile1 = async e => {
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image.jpg"
    ) {
      setInfoFile1(file);
    } else {
      AlertWithoutButton(
        "error",
        "003: Error. selecciona un formato de imagen valido"
      );
    }
  };

  const goDownloadVirtualCard = async () => {
    if(infoCode.name === ""){
      AlertWithoutButton(
        "error",
        "Debes ingresar la información requerida",
      );
    }else if(infoCode.pathImage === ""){
      AlertWithoutButton(
        "error",
        "Se ha presentado un error al descargar la tarjeta virtual, por favor comuniquese con el administrador",
      );
    }else{
      setIsDowloading(true);
      setButtonVcard("Descargando")
      const resp = await downloadVirtualCard(codeEdit);
      setIsDowloading(false);
      setButtonVcard("Descargar tarjeta virtual");
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = event => {
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.download = `${codeEdit}.png`;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      };
      xhr.open("GET", resp);
      xhr.send();
    }
   
  };

  return (
    <div className="edit__form">
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <div>
            <Label
              text="Nombre"
              color={fieldRequired[0].name === "name" && "#FF0000"}
            />
            <div className="edit__div-input">
              <Input
                name="name"
                changeInput={changeInput}
                value={values.name}
              />
            </div>
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label
            text="Apellido"
            color={fieldRequired[1].name === "lastName" && "#FF0000"}
          />
          <div className="edit__div-input">
            <Input
              name="lastName"
              changeInput={changeInput}
              value={values.lastName}
            />
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label
            text="Teléfono"
            color={fieldRequired[2].name === "phone" && "#FF0000"}
          />
          <div className="edit__div-input">
            <Input
              type="number"
              name="phone"
              changeInput={changeInput}
              value={values.phone}
            />
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label
            text="Correo"
            color={fieldRequired[4].name === "email" && "#FF0000"}
          />
          <div className="edit__div-input">
            <Input
              name="email"
              changeInput={changeInput}
              value={values.email}
            />
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Tipo de sangre" />
          <div className="edit__div-select">
            {infobloodType.type &&
              <Select
                options={infobloodType.type}
                changeInput={changeInput}
                name="blood"
                value={values.blood}
              />}
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="blood"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Enfermedades patológicas" required={false} />
          <div className="edit__div-text-area">
            <TextArea
              name="diseases"
              changeInput={changeInput}
              value={values.diseases}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="diseases"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Alergias" required={false} />
          <div className="edit__div-text-area">
            <TextArea
              name="allergies"
              changeInput={changeInput}
              value={values.allergies}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="allergies"
          />
        </div>
      </div>
      {/* <OptionalInput changeInput={changeInput} values={values.company} name="company" text="Empresa" /> */}
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Empresa" required={false} />
          <div className="edit__div-input">
            <Input
              name="company"
              changeInput={changeInput}
              value={values.company}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="company"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="País" />
          <div className="edit__div-select">
            {infoCountryCode.listIndicative &&
              <SelectIndicative
                options={infoCountryCode.listIndicative}
                changeInput={changeInput}
                name="country"
                value={values.country}
                setIndicative={setIndicative}
              />}
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label
            text="Ciudad"
            color={fieldRequired[3].name === "city" && "#FF0000"}
          />
          <div className="edit__div-input">
            <Input name="city" changeInput={changeInput} value={values.city} />
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Dirección" required={false} />
          <div className="edit__div-input">
            <Input
              name="address"
              changeInput={changeInput}
              value={values.address}
            />
          </div>
        </div>
        <div className="edit__div-field" />
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="EPS" required={false} />
          <div className="edit__div-input">
            <Input name="EPS" changeInput={changeInput} value={values.EPS} />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="EPS"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Medicina prepagada" required={false} />
          <div className="edit__div-input">
            <Input
              name="prepaidMedicine"
              changeInput={changeInput}
              value={values.prepaidMedicine}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="prepaidMedicine"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Cargo actual" required={false} />
          <div className="edit__div-input">
            <Input
              name="profession"
              changeInput={changeInput}
              value={values.profession}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="profession"
          />
        </div>
      </div>
      <h3 className="edit__subtitle">Contacto adicional</h3>
      <label className="edit__label">Deseas inactivar el botón S.O.S</label>
      <OptionalInput
        optionalInputs={optionalInputs}
        setOptionalInputs={setOptionalInputs}
        name="buttonSOS"
      />
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Nombre contacto" required={false} />
          <div className="edit__div-input">
            <Input
              name="contactName"
              changeInput={changeInput}
              value={values.contactName}
            />
          </div>
        </div>
        <div className="edit__div-field">
          {/* <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="contactName"
          /> */}
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Teléfono contacto" required={false} />
          <div className="edit__div-input edit__div-last-input">
            <Input
              type="number"
              name="contactPhone"
              changeInput={changeInput}
              value={values.contactPhone}
            />
          </div>
        </div>
        <div className="edit__div-field">
          {/* <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="contactPhone"
          /> */}
        </div>
      </div>
      {/* <div className="edit__div-fiel-input">
        <div className="edit__div-field"></div>
        <div className="edit__container-input">
          <Label
            text="Email contacto"
            color={fieldRequired[5].name === "emailContact" && "#FF0000"}
          />
          <div className="edit__email-adicional-info">
            (A este correo llegará la ubicación en caso de escaneo)
          </div>
          <div className="edit__div-input">
            <Input
              name="emailContact"
              changeInput={changeInput}
              value={values.emailContact}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="contactEmail"
          />
        </div>
      </div> */}
      <h3 className="edit__subtitle">Datos médico personal</h3>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Nombre médico" required={false} />
          <div className="edit__div-input">
            <Input
              name="doctorName"
              changeInput={changeInput}
              value={values.doctorName}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="doctorName"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Teléfono médico" required={false} />
          <div className="edit__div-input edit__div-last-input">
            <Input
              type="number"
              name="doctorPhone"
              changeInput={changeInput}
              value={values.doctorPhone}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="doctorPhone"
          />
        </div>
      </div>
      <h3 className="edit__subtitle">Redes sociales</h3>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Instagram" required={false} />
          <div className="edit__div-input">
            <Input
              name="instagram"
              changeInput={changeInput}
              value={values.instagram}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="instagram"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Facebook" required={false} />
          <div className="edit__div-input">
            <Input
              name="facebook"
              changeInput={changeInput}
              value={values.facebook}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="facebook"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Url LinkedIn" required={false} />
          <div className="edit__div-input">
            <Input
              name="tiktok"
              changeInput={changeInput}
              value={values.tiktok}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="tiktok"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Sitio web (www.example.com)" required={false} />
          <div className="edit__div-input">
            <Input
              name="webSite"
              changeInput={changeInput}
              value={values.webSite}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="webSite"
          />
        </div>
      </div>
      <h3 className="edit__subtitle">Información vacuna covid-19</h3>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <div className="edit__container-group-photo-vaccine">
            <div className="edit__container-avatar-vaccine">
            <p className="edit__text-description-field">Foto frontal carnet vacuna</p>
              <Avatar
                className="edit__div-image-vaccine"
                urlPhoto={
                  infoCode["linkPhotoVaccine"][0]
                    ? infoCode["linkPhotoVaccine"][0]
                    : ""
                }
                urlAvatar={urlAvatar}
                type="vaccine"
              />
              <div className="edit__div-icon-zoom">
                <div className="edit__container-icon-zoom">
                  <div>
                    <Fullscreen
                      className="edit__icon-zoom"
                      onClick={() => openModal(0)}
                    />
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="fileSelectorVaccine"
                name="fileVaccine"
                style={{ display: "none" }}
                onChange={eventInputFile}
              />
              <div className="edit__container-button-avatar">
                <div
                  className="edit__div-button-avatar edit-div-button-vaccine-text"
                  onClick={() => openFile(1)}
                  style={
                    loading.vaccine
                      ? { backgroundColor: "#F27200" }
                      : { backgroundColor: "#2b4763" }
                  }
                >
                  {textButtonPhoto}
                </div>
              </div>
            </div>
            <div className="edit__container-avatar-vaccine">
            <p className="edit__text-description-field">Foto respaldo carnet vacuna</p>
              <Avatar
                className="edit__div-image-vaccine"
                urlPhoto={
                  infoCode["linkPhotoVaccine"][1]
                    ? infoCode["linkPhotoVaccine"][1]
                    : ""
                }
                urlAvatar={urlAvatar1}
                type="vaccine"
              />
              <div className="edit__div-icon-zoom">
                <div className="edit__container-icon-zoom">
                  <div>
                    <Fullscreen
                      className="edit__icon-zoom"
                      onClick={() => openModal(1)}
                    />
                  </div>
                </div>
              </div>
              <input
                type="file"
                id="fileSelectorVaccine1"
                name="fileVaccine1"
                style={{ display: "none" }}
                onChange={eventInputFile1}
              />
              <div className="edit__container-button-avatar">
                <div
                  className="edit__div-button-avatar edit-div-button-vaccine-text"
                  onClick={() => openFile(2)}
                  style={
                    loading.vaccine1
                      ? { backgroundColor: "#F27200" }
                      : { backgroundColor: "#2b4763" }
                  }
                >
                  {textButtonPhoto2}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="photoVaccine"
          />
        </div>
      </div>
      <div className="edit__div-fiel-input">
        <div className="edit__div-field" />
        <div className="edit__container-input">
          <Label text="Nombre vacuna COVID-19" required={false} />
          <div className="edit__div-input">
            <Input
              name="nameVaccineCovid"
              changeInput={changeInput}
              value={values.nameVaccineCovid}
            />
          </div>
        </div>
        <div className="edit__div-field">
          <OptionalInput
            optionalInputs={optionalInputs}
            setOptionalInputs={setOptionalInputs}
            name="vaccineCovid"
          />
        </div>
      </div>
      <div className="view__div-virtual-card">
        <div className="edit__container-button-avatar">
                <div
                  className="edit__div-button-avatar edit-div-button-vaccine-text"
                  onClick={goDownloadVirtualCard}
                  style={
                    isDowloading
                      ? { backgroundColor: "#F27200" }
                      : { backgroundColor: "#2b4763" }
                  }
                >
                  {buttonVcard}
                </div>
              </div>
      </div>
      <p className="edit__text-description-field">
        Con este icono <EyeFill color="red" /> permitirás en tu manilla de
        visualización ver los campos u ocultarlos
      </p>
      <div className="edit__div-button">
        <Button submit={saveInfoCode} textButton={textButton} />
      </div>
      <ModalPhoto url={imageModal} handleClose={handleClose} show={show} />
    </div>
  );
};
