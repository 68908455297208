import { urlProduction } from "../utils/constants";

export async function getInfoCodeEdit(codeEdit) {
  let infoCode = {};
  await fetch(
    `${urlProduction}/api/codes/codeEdit/?key=${codeEdit}`
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      infoCode = response;
    });

  return infoCode;
}

export async function saveUrlPhoto(id, url, namePhoto) {
  let result = false;
  let apiPhoto = "editUrlImage";

  let requestOptions = {
    method: "POST",
    body:url
  };

  if(namePhoto!=="avatarUser"){
    apiPhoto="editUrlImageVaccine";
    requestOptions = {
      method: "POST",
      body:JSON.stringify(url)
    };
  }

  await fetch(
    `${urlProduction}/api/codes/${apiPhoto}?id=${id}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      result = response;
    });

  return result;
}

export async function saveInformationCode(infoCode, id){

  let result = false;

  const requestOptions = {
    method: "POST",
    body:JSON.stringify(infoCode),

  };

  await fetch(
    `${urlProduction}/api/codes/saveInfoCode?id=${id}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      result = response;
    });

  return result;

}

export async function getInfoCodeView(codeView) {
  let infoCode = {};
  await fetch(
    `${urlProduction}/api/codes/codeView/?key=${codeView}`
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      infoCode = response;
    });

  return infoCode;
}

export async function sendEmail(email, name, lat, lng){

    const infoEmail={
      'email':email,
      'name':name,
      'latitude':lat,
      'longitude':lng
    }

    const requestOptions = {
      method: "POST",
      body:JSON.stringify(infoEmail),
    };

    await fetch(`${urlProduction}/api/codes/sendEmail/`, requestOptions)
    .then((response)=>{
      response.json();
    })
    .then((response)=>{
    });

}

export async function sendSMS(phone, name, lat, lng){

  const infoUser={
    'phone':phone,
    'name':name,
    'latitude':lat,
    'longitude':lng
  }

  const requestOptions = {
    method: "POST",
    body:JSON.stringify(infoUser),
  };

  await fetch(`${urlProduction}/api/codes/sendSMS/`, requestOptions)
  .then((response)=>{
    response.json();
  })
  .then((response)=>{
  });

}

export async function updateTerms(terms){

  let result = false;
  
  const requestOptions = {
    method: "POST",
    body:JSON.stringify(terms),
  };

  await fetch(`${urlProduction}/api/codes/updateTerms/`, requestOptions)
  .then((response)=>{
    response.json();
  })
  .then((response)=>{
    result=true;
  });

  return result;

}

export async function downloadVirtualCard(codeView){

  let url = "";
  let image=false;

  const requestOptions = {
    method: "POST",
    body:codeView,
  };

  await fetch(
    `${urlProduction}/api/codes/downloadVirtualCard`,
    requestOptions
  ).then((response)=>{
    return response.json();
  })
  .then((response)=>{
    url=response;
  })

    if(url!==""){
      image=window.URL.href=url;
    }else{
      image=false;
    }
  return image;
    
}

