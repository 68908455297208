import React from 'react';

export const Label = ({ required = true, text, color='' }) => {
  return (
    <>
      {required ? (
        <div className="input__div-label">
            <div className="input__div-label-required">
          <label className="input__label-required">*</label>
          </div>
          <div className="input__div-label-required-text">
          <label className="edit__label" style={{color:color}}>{text}</label>
          </div>
        </div>
      ) : (
        <div className="input__div-label">
          <label className="edit__label">{text}  <label className="edit__optional">(opcional)</label></label>
         
        </div>
      )}
    </>
  );
};
