import React from 'react';

export const ScreenIndex = () => {
    return (
        <>
        <div className="index__header-body">
            <h1 className="index__header-text">Personal-id</h1>
        </div>
        <div>
        <h5 className="index__text-description">Por favor escanea alguna de tus placas para ver o editar la información de tu familiar</h5>
        </div>
        </>

    )
}
