import { size } from "lodash";
import { saveUrlPhoto } from "../api/apiCode";
import { AlertWithoutButton } from "../components/alert/Alert";
import { savePhotoBD } from "../model/modelStorage";

export async function saveImageCloud(idCode, infoFile, namePhoto, pathPhoto, setLoading, setInfoFile, listUrlImage, setListUrlImage, urlVaccine, urlVaccine1){

    let urlPhoto = "";

    if (infoFile !== "") {
        let urlImage = "";
        await savePhotoBD(idCode, infoFile, namePhoto, pathPhoto)
          .then((response) => {
            urlImage = response;
          })
          .catch(() => {
            if(namePhoto==="avatarUser"){
              setLoading(false);
            }else{
              setLoading({save:false, vaccine:false, vaccine1:false});
            }
            AlertWithoutButton("error", "001: Error actualizando la foto");
          });
        if (urlImage !== "") {
          const arrayVaccine = [];
          if(namePhoto!=="avatarUser"){
       
            if(namePhoto==="vaccineUser"){
              if(size(listUrlImage)>0 || urlVaccine1){
                arrayVaccine.push(urlImage);
                arrayVaccine.push(size(listUrlImage)>0 ? listUrlImage[1] : urlVaccine1);
              }else{
                arrayVaccine.push(urlImage);
                arrayVaccine.push("");
              }
            }else{
              if(size(listUrlImage)>0 || urlVaccine){
                arrayVaccine.push(size(listUrlImage)>0  ? listUrlImage[0] : urlVaccine);
                arrayVaccine.push(urlImage);
              }else{
                arrayVaccine.push("");
                arrayVaccine.push(urlImage);
              }
            }

            setListUrlImage(arrayVaccine);
          }

          await saveUrlPhoto(idCode, size(arrayVaccine)>0 ? arrayVaccine : urlImage, namePhoto)
            .then((response) => {
              if (response) {
                urlPhoto=urlImage;
                AlertWithoutButton("success", "Tu foto ha sido actualizada");
              }
              if(namePhoto==="avatarUser"){
                setLoading(false);
              }else{
                setLoading({save:false, vaccine:false, vaccine1:false});
              }
              urlImage = "";
            })
            .catch(() => {
              setLoading({save:false, vaccine:false, vaccine1:false});
              AlertWithoutButton("error", "002: Error actualizando la foto");
            });
          setInfoFile("");
        } else {
          if(namePhoto==="avatarUser"){
            setLoading(false);
          }else{
            setLoading({save:false, vaccine:false, vaccine1:false});
          }
        }
      } else {
        if(namePhoto==="avatarUser"){
          setLoading(false);
        }else{
          setLoading({save:false, vaccine:false, vaccine1:false});
        }
      }

      return urlPhoto;
}
