import firebase from 'firebase/app';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD0gHmr_BT2IS0h3BzX018Kz6z1YVcKDmw",
  authDomain: "personal-id-68cb8.firebaseapp.com",
  projectId: "personal-id-68cb8",
  storageBucket: "personal-id-68cb8.appspot.com",
  messagingSenderId: "196995517588",
  appId: "1:196995517588:web:a3cb2f631b1a3ff56c3881"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;