import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Button = ({ className="edit__button",submit, textButton }) => {

  const [colorButton, setColorButton] = useState('#2b4763');

  useEffect(() => {
      if(textButton==='Guardando'){
        setColorButton('#F27200');
      }else{
        setColorButton('#2b4763');
      }
  }, [setColorButton,textButton]);

  return (
    <button className={className} type="button" onClick={submit} style={{backgroundColor:colorButton}}>
      {textButton}
    </button>
  );
};

Button.propTypes = {
  submit: PropTypes.func.isRequired,
};
