import React from 'react';
import { Modal as ModalB } from "react-bootstrap";
import IconVaccine from "../../assets/iconVaccine.jpeg";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { size } from 'lodash';

export const ModalPhoto = ({url, handleClose, show}) => {

  const onClose = () =>{
    handleClose();
  }
  let listUrl="";
  let isArray=false;
  
  if(size(url)>1 && url instanceof Array && url[0]!=="" && url[1] !==""){
    listUrl=url;
    isArray=true;
  }else if(size(url)>1 && url instanceof Array && url[0]!==""){
    listUrl=url[0];
    isArray=false;
  }else if(size(url)>1 && url instanceof Array && url[1] !==""){
    listUrl=url[1];
    isArray=false;
  }else{
    if(url!==""){
      listUrl=url
    }else{
      listUrl=IconVaccine;    
    }
     isArray=false;
  }
    return (
        <>
        <ModalB show={show} onHide={handleClose} fullscreen={true}>
          {/* <ModalB.Header closeButton>
            <ModalB.Title>Foto vacuna covid</ModalB.Title>
          </ModalB.Header> */}
          <ModalB.Body>
          {/* <div className="view__container-modal-vaccine">
            <Image src={url ? url : IconVaccine} alt="Image" className="view__photo-vaccine" />
          </div> */}
          {isArray ?(
            <Lightbox images={listUrl} onClose={onClose}/>
          ):(
            <Lightbox image={listUrl} onClose={onClose}/>
          )}
          
          </ModalB.Body>
        </ModalB>
      </>
    )
}
