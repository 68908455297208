import React from "react";
import PropTypes from "prop-types";

export const SelectIndicative = ({
  options,
  changeInput,
  name,
  value,
  setIndicative,
}) => {

  if (value === '') {
    const target = {
      name: 'country',
      value: 'Colombia',
    };
    changeInput({ target });
  }


  const putIndicative = (valueSelect) => {

    options.forEach((option) => {
      if (option.infoIndicative[0] === valueSelect) {
        setIndicative(option.infoIndicative[1]);
      }
    });

    const target = {
      name: "country",
      value: valueSelect,
    };

    changeInput({ target });
  };

  return (
    <select
      className="input__select"
      // defaultValue={"DEFAULT"}
      onChange={(e) => putIndicative(e.target.value)}
      name={name}
      value={value !== '' ? value : 'Colombia'}
    >
      {/* {textDefault !== "" && <option value="DEFAULT">{textDefault}</option>} */}
      <option value="+57">Colombia</option>
      {options &&
        options.map(
          (option, index) =>
            option.infoIndicative[0] !== "Colombia" && (
              <option key={index} value={option.infoIndicative[0]}>
                {option.infoIndicative[0]}
              </option>
            )
        )}
    </select>
  );
};

SelectIndicative.propTypes = {
  options: PropTypes.array.isRequired,
  changeInput: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
