import Swal from 'sweetalert2';

export function AlertWithoutButton(type, text){

    Swal.fire({
        icon: type,
        text: text,
        showConfirmButton: false,
        timer: 1500,
        width: 250,
      }); 

}