import React from 'react';
import IconPerson from '../../assets/iconPerson-2.jpeg';

export const AvatarView = ({urlPhoto}) => {
  return (
    <img
      className="view__image-avatar"
      src={urlPhoto ? urlPhoto : IconPerson}
      alt="avatar"
    />
  );
};
