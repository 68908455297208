import React, { useEffect, useState } from "react";
import { Form } from "../components/form/Form";
import { useParams } from "react-router-dom";
import { getInfoCodeEdit } from "../api/apiCode";
import {
  getInfoBloodType,
  getInfoCountryCode,
  getOptionalField,
} from "../api/apiParameter";
import { Avatar } from "../components/avatar/Avatar";
import { AlertWithoutButton } from "../components/alert/Alert";
import { saveImageCloud } from "../helpers/SaveImageStorage";
import { size } from "lodash";
import { ModalTerms } from "../components/modal/ModalTerms";

export const ScreenEdit = () => {
  let { codeEdit } = useParams();
  const [infobloodType, setInfoBloodType] = useState([]);
  const [infoCountryCode, setInfoCountryCode] = useState([]);
  const [loading, setLoading] = useState(0);
  const [dataCode, setDataCode] = useState(valueDefault());
  const [idCode, setIdCode] = useState("");
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [infoFile, setInfoFile] = useState("");
  const [urlAvatar, setUrlAvatar] = useState("");
  const [textButton, setTextButton] = useState("Cargar foto");
  const [optionalInputs, setOptionalInputs] = useState({
    change: false,
    listOptionalInput: [],
  });
  const [showModalTerms, setShowModalTerms] = useState(false);

  useEffect(() => {
    if (loadingPhoto) {
      setTextButton("Cargando");
    } else if (dataCode["linkPhoto"] !== "") {
      setTextButton("Editar foto");
    } else {
      setTextButton("Cargar foto");
    }
  }, [loadingPhoto, setTextButton, dataCode]);

  useEffect(() => {
    async function getInfoPage() {
      let countryCode = false;
      let bloodType = false;
      let code = false;
      let field = false;
      let id = "";
      let terms = false;
      setLoading(true);

      await getInfoCodeEdit(codeEdit)
        .then((response) => {
          code = true;
          if (response.data) {
            id = response.id;
            setIdCode(response.id);
            setDataCode(response.data);
            terms=response.data?.terms;
          }
        })
        .catch(() => {
          code = true;
        });
        if(terms === false || terms === undefined){
          setShowModalTerms(true);
        }
 
      await getInfoBloodType()
        .then((response) => {
          bloodType = true;
          setInfoBloodType(response);
        })
        .catch(() => {
          bloodType = true;
        });

      await getInfoCountryCode()
        .then((response) => {
          countryCode = true;
          setInfoCountryCode(response);
        })
        .catch(() => {
          countryCode = true;
        });

      if (id) {
        let arrayField = [];
        await getOptionalField(id)
          .then((response) => {
            field = true;
            if (size(response) > 1) {
              arrayField = response.data.listField;
            }
          })
          .catch(() => {
            field = true;
          });
        if (size(arrayField) > 0) {
          setOptionalInputs({
            change: false,
            listOptionalInput: arrayField,
          });
        }
      }

      if (code && bloodType && countryCode && field) {
        setLoading(false);
      }
    }

    getInfoPage();
  }, [codeEdit]);

  useEffect(() => {
    if (infoFile !== "") {
      setLoadingPhoto(true);
      saveImageCloud(
        idCode,
        infoFile,
        "avatarUser",
        "photoUser",
        setLoadingPhoto,
        setInfoFile
      )
        .then((response) => {
          setUrlAvatar(response);
        })
        .catch(() => {
          AlertWithoutButton("error", "004: Error actualizando la foto");
        });
    }
  }, [infoFile, idCode]);

  const openFile = () => {
    document.querySelector("#fileSelector").click();
  };

  const eventInputFile = async (e) => {
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image.jpg"
    ) {
      setInfoFile(file);
    } else {
      AlertWithoutButton(
        "error",
        "003: Error. selecciona un formato de imagen valido"
      );
    }
  };

  return (
    <>
      {!loading && (
        <div className="edit__container">
          <div className="edit__lateral"></div>
          <div className="edit__body">
            <div className="edit__div-avatar-button">
              <Avatar
                urlPhoto={dataCode ? dataCode["linkPhoto"] : ""}
                urlAvatar={urlAvatar}
              />
              <input
                type="file"
                id="fileSelector"
                name="file"
                style={{ display: "none" }}
                onChange={eventInputFile}
              />
              <div
                className="edit__div-button-avatar"
                onClick={openFile}
                style={
                  loadingPhoto
                    ? { backgroundColor: "#F27200" }
                    : { backgroundColor: "#2b4763" }
                }
              >
                {textButton}
              </div>
            </div>
            <Form
              infobloodType={infobloodType}
              infoCountryCode={infoCountryCode}
              idDoc={idCode}
              infoCode={dataCode}
              loadingPhoto={loadingPhoto}
              setInfoFile={setInfoFile}
              urlAvatar={urlAvatar}
              optionalInputs={optionalInputs}
              setOptionalInputs={setOptionalInputs}
              codeEdit={codeEdit}
            />
          </div>
          <div className="edit__lateral"></div>
          <ModalTerms showModal={showModalTerms} idCode={idCode} />
        </div>
      )}
    </>
  );
};

function valueDefault() {
  return {
    name: "",
    lastName: "",
    phone: "",
    blood: "A+",
    diseases: "",
    allergies: "",
    company: "",
    address: "",
    country: "Colombia",
    city: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    webSite: "",
    contactName: "",
    contactPhone: "",
    doctorName: "",
    doctorPhone: "",
    EPS: "",
    prepaidMedicine: "",
    profession: "",
    indicative: "+57",
    email: "",
    emailContact: "",
    nameVaccineCovid: "",
    linkPhotoVaccine:"",
    linkPhoto:""
  };
}
