import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({className="input__input",type = "text", name, changeInput, value=""}) => {

  return (
    <input
      className={className}
      type={type}
      name={name}
      autoComplete="off"
      onChange={changeInput}
      value={value}
    />
  );
};

Input.propTypes = {
  name:PropTypes.string.isRequired,
  changeInput:PropTypes.func.isRequired
}
