import React from "react";
import Image from 'react-bootstrap/Image'
import IconPerson from "../../assets/iconPerson-3.jpeg";
import IconVaccine from "../../assets/iconVaccine.jpeg";

export const Avatar = ({ className="edit__div-avatar", urlPhoto, urlAvatar, type="photo" }) => {

  let icon = IconPerson;

  if (urlPhoto === undefined) {
    urlPhoto = "";
  }

  if(type==="vaccine"){
    icon=IconVaccine;
  }

  return (
      <div className={className}>
        {urlAvatar !== "" ? (
          <Image className="edit__photo" src={urlAvatar} alt="imagen"/>
        ) : (
          <Image
            className="edit__photo"
            src={urlPhoto !== "" ? urlPhoto : icon}
            alt="imagen"
          />
        )}
      </div>
  );
};
