import React from "react";
import { ItemInformation } from "../information/ItemInformation";

export const Information = ({ infoCode, checkFields }) => {
  return (
    <>
      <div className="view__div-information-user">
        {/* <ItemInformation
          label="Nombre"
          data={`${infoCode.name} ${infoCode.lastName}`}
          classInformation="view__div-information"
        /> */}
          <ItemInformation
            label="Ciudad"
            data={infoCode.city}
            classInformation="view__div-information"
          />
        {infoCode.address !== "" && (
          <ItemInformation
            label="Dirección"
            data={infoCode.address}
            classInformation="view__div-information"
          />
        )}
        {checkFields.blood && (
          <ItemInformation
            label="Tipo de sangre"
            data={infoCode.blood}
            classInformation="view__div-information"
          />
        )}
        {checkFields.company && (
          <ItemInformation
            label="Empresa"
            data={infoCode.company}
            classInformation="view__div-information"
          />
        )}
        {checkFields.webSite && (
          <ItemInformation
            label="Página web"
            data={infoCode.webSite}
            classInformation="view__div-information"
            link={true}
          />
        )}
      </div>
    </>
  );
};
