import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { size } from "lodash";
import moment from "moment-timezone";
import { AvatarView } from "../components/avatar/AvatarView";
import { IconView } from "../components/icons/IconView";
import { Information } from "../components/view/Information";
import IconEmail from "../assets/iconEmail.png";
import IconWhatsapp from "../assets/iconoWhat.png";
import IconPhone from "../assets/phoneIcon.png";
import { getInfoCodeView, sendSMS } from "../api/apiCode";
import { AlertWithoutButton } from "../components/alert/Alert";
import { InfoAdicional } from "../components/view/InfoAdicional";
import { getInfoPlan } from "../api/apiSuscription";
import { Modal } from "../components/modal/Modal";
import { getOptionalField } from "../api/apiParameter";
import { validateOptionalFields } from "../utils/validateFields";
import {
  validateFacebook,
  validateInstagram,
  validateLinkedIn,
} from "../utils/validateSocialNetwork";
import IconFacebook from "../assets/iconFacebook.png";
import IconInstagram from "../assets/iconInstagram.png";
import IconLinkedIn from "../assets/iconLinkedIn.png";
import { InfoVaccine } from "../components/view/InfoVaccine";

export const ScreenView = () => {
  let { codeView } = useParams();
  const [infoCode, setinfoCode] = useState(valueDefault());
  const [infoSuscription, setInfoSuscription] = useState({});
  const [isDuePlan, setIsDuePlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSelected, setButtonSelected] = useState({
    button: 1,
    button1: "#FF0000",
    button2: "#FFF",
  });
  const [checkButtonSOS, setCheckButtonSOS] = useState(false);
  const [checkFields, setCheckFields] = useState(checkFieldDefault());

  useEffect(() => {
    setLoading(true);

    let resutCode = false;
    let resultPlan = false;
    let resultField = false;
    let arrayFields = [];

    async function getInfoCode() {
      let idDoc = "";
      await getInfoCodeView(codeView)
        .then((response) => {
          if (size(response) > 0) {
            idDoc = response.id;
            setinfoCode(response.data);
          }
          resutCode = true;
        })
        .catch(() => {
          resutCode = true;
        });

      if (idDoc !== "") {
        await getInfoPlan(idDoc)
          .then((response) => {
            resultPlan = true;
            if (size(response) > 0) {
              setInfoSuscription(response);
            }
          })
          .catch(() => {
            resultPlan = true;
          });
        await getOptionalField(idDoc)
          .then((response) => {
            resultField = true;
            if (size(response) > 1) {
              arrayFields = response.data.listField;
            }
          })
          .catch(() => {
            resultField = true;

          });
      }
      await validateOptionalFields(arrayFields, checkFieldDefault).then(
        (response) => {
          setCheckFields(response);
        }
      );

      if (resutCode && resultPlan && resultField) {
        setLoading(false);
      }
    }
    getInfoCode();
  }, [setinfoCode, codeView]);

  useEffect(() => {
    if (size(infoSuscription) > 0) {
      
      //console.log(infoSuscription);
      let resultDate;
      const dateNow = moment(new Date(), "DD/MM/YYYY HH:mm:ss").tz(
        "America/Bogota"
      );
      const dataPlan = moment(infoSuscription.dueDate, "DD/MM/YYYY HH:mm:ss");
      resultDate = dataPlan.diff(dateNow, "minutes");
      if (resultDate + 1440 < 0) {
        setIsDuePlan(true);
      }
      if (
        infoSuscription.status === "EXPIRED" ||
        infoSuscription.status === "CANCELED"
      ) {
        setIsDuePlan(true);
      }
    }
  }, [infoSuscription]);

  // useEffect(() => {
  //   const infoWindow = "";
  //   const map = "";
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         const lat = position.coords.latitude;
  //         const lng = position.coords.longitude;

  //         if (infoCode.emailContact !== "" && infoCode.name !== "") {
  //           sendEmail(infoCode.emailContact, infoCode.name, lat, lng);
  //         }
  //       },
  //       function () {
  //         handleLocationError(true, infoWindow, map.getCenter());
  //       }
  //     );
  //   } else {
  //     handleLocationError(false, infoWindow, map.getCenter());
  //   }

  //   function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  //     infoWindow.setPosition(pos);
  //     infoWindow.setContent(
  //       browserHasGeolocation
  //         ? "Error: The Geolocation service failed."
  //         : "Error: Your browser doesn't support geolocation."
  //     );
  //   }
  // }, [infoCode]);

  // function goSendEmail() {
  //   const infoWindow = "";
  //   const map = "";
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         const lat = position.coords.latitude;
  //         const lng = position.coords.longitude;

  //         if (infoCode.emailContact !== "" && infoCode.name !== "") {
  //           sendEmail(infoCode.emailContact, infoCode.name, lat, lng);
  //         }
  //       },
  //       function () {
  //         handleLocationError(true, infoWindow, map.getCenter());
  //       }
  //     );
  //   } else {
  //     handleLocationError(false, infoWindow, map.getCenter());
  //   }

  //   function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  //     infoWindow.setPosition(pos);
  //     infoWindow.setContent(
  //       browserHasGeolocation
  //         ? "Error: The Geolocation service failed."
  //         : "Error: Your browser doesn't support geolocation."
  //     );
  //   }
  // }

  function goSendSMS() {
    const infoWindow = "";
    const map = "";
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          if (infoCode.contactPhone !== "" && infoCode.name !== "") {
            sendSMS(
              infoCode.indicative + infoCode.contactPhone,
              infoCode.name,
              lat,
              lng
            );
          }
        },
        function () {
          handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      handleLocationError(false, infoWindow, map.getCenter());
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
    }
  }

  const goWhatsapp = () => {
    if (infoCode.phone !== "") {
      window.location.href = `https://wa.me/${infoCode.indicative}${infoCode.phone}`;
    } else {
      AlertWithoutButton(
        "error",
        "Debes agregar un teléfono en la pagina de edición"
      );
    }
  };

  const goWhatAditional = () => {
    if (infoCode.contactPhone !== "") {
      //goSendSMS();
      window.location.href = `https://wa.me/${infoCode.indicative}${infoCode.contactPhone}`;
    } else {
      AlertWithoutButton(
        "error",
        "Debes agregar un teléfono de contacto adicional en la pagina de edición"
      );
    }
    //goSendEmail();
  };

  const goPhone = () => {
    if (infoCode.phone !== "") {
      window.location.assign(`tel:${infoCode.indicative}${infoCode.phone}`);
    } else {
      AlertWithoutButton(
        "error",
        "Debes agregar un teléfono en la pagina de edición"
      );
    }
  };

  const goPhoneAditional = () => {
    if (infoCode.contactPhone !== "") {
      // goSendSMS();
      window.location.assign(
        `tel:${infoCode.indicative}${infoCode.contactPhone}`
      );
    } else {
      AlertWithoutButton(
        "error",
        "Debes agregar un teléfono de contacto adicional en la pagina de edición"
      );
    }
    //goSendEmail();
  };

  const goGmail = () => {
    if (infoCode.email !== "") {
      window.location.assign(`mailto:${infoCode.email}`);
    } else {
      AlertWithoutButton(
        "error",
        "ºDebes agregar un correo en la pagina de edición"
      );
    }
  };

  const goSocialNetwork = async (social) => {
    if (social === "Facebook") {
      if (infoCode.facebook !== "") {
        const result = await validateFacebook(infoCode.facebook);
        if (result !== "") {
          window.location.href = result;
        }
      } else {
        AlertWithoutButton(
          "error",
          "No hay información registrada para esta red social"
        );
      }
    } else if (social === "Instagram") {
      if (infoCode.instagram !== "") {
        const result = await validateInstagram(infoCode.instagram);
        if (result !== "") {
          window.location.href = result;
        }
      } else {
        AlertWithoutButton(
          "error",
          "No hay información registrada para esta red social"
        );
      }
    } else if (social === "LinkedIn") {
      if (infoCode.tiktok !== "") {
        const result = await validateLinkedIn(infoCode.tiktok);
        if (result !== "") {
          window.location.href = result;
        }
      } else {
        AlertWithoutButton(
          "error",
          "No hay información registrada para esta red social"
        );
      }
    }
  };

  const changeButton = (button) => {

      setButtonSelected({
        button: button,
      });

  };

  const goButtonsEmergency = () => {
    setCheckButtonSOS(true);
    goSendSMS();
  };

  if (!loading) {
    if (isDuePlan) {
      window.location.href = `/web/response/edit/suscription`;
    }
  }

  return (
    <>
      {!loading && (
        <div className="view__body">
          <div className="view__container">
            <div className="view__div-avatar">
              <AvatarView urlPhoto={infoCode ? infoCode.linkPhoto : ""} />
            </div>
            <div className="view__container-name">
              <h5 className="view__name">{`${infoCode.name} ${infoCode.lastName}`}</h5>
              {checkFields.profession &&(
                <p className="view__text-information">{infoCode.profession}</p>
              )}
            </div>
            {/* <div className="view__div-buttons-tab">
              <div className="view__container-buttons-tab">
                <div
                  className="view__button-tab-left"
                  onClick={() => changeButton(1)}
                >
                  <h5 className="view__text-button-tab">Info contacto</h5>
                  <div
                    className="view__button-tab-circle"
                    style={{ backgroundColor: buttonSelected.button1 }}
                  ></div>
                </div>
                <div
                  className="view__button-tab-right"
                  onClick={() => changeButton(2)}
                >
                  <div
                    className="view__button-tab-circle"
                    style={{ backgroundColor: buttonSelected.button2 }}
                  ></div>
                  <h5 className="view__text-button-tab">Info adicional</h5>
                </div>
              </div>
            </div> */}
            <div className="view__container-line">
              <div className="view__div-line view__line-blue"></div>
              <div className="view__div-line view__line-gray"></div>
              <div className="view__div-line view__line-red"></div>
            </div>
            {buttonSelected.button === 1 ? (
              <div className="view__div-container-information animate__animated animate__fadeIn">
                {/* <div className="view__container-name">
                  <h4 className="view__name">{`${infoCode.name} ${infoCode.lastName}`}</h4>
                </div> */}
                <div className="view__container-information">
                  <Information infoCode={infoCode} checkFields={checkFields} />
                </div>
                <div className="view__container-menu">
                {checkFields.buttonSOS &&(
                  <div className="view__button-menu view__line-red" onClick={goButtonsEmergency}>Contacto de emergencia</div>
                )}
                  <div className="view__button-menu view__line-blue" onClick={() => changeButton(2)}>Ver información adicional</div>
                  <div className="view__button-menu view__line-blue" onClick={() => changeButton(3)}>Ver información de vacunas</div>
                </div>
                <div className="view__container-menu">
                  <h5 className="view__title-icons">Canales de contacto:</h5>
                </div>
                <div className="view__icons-user">
                  <div className="view__container-icon" onClick={goPhone}>
                    <IconView urlImage={IconPhone} />
                  </div>
                  <div className="view__container-icon" onClick={goWhatsapp}>
                    <IconView urlImage={IconWhatsapp} />
                  </div>
                  <div className="view__container-icon" onClick={goGmail}>
                    <IconView urlImage={IconEmail} />
                  </div>
                {checkFields.facebook && (
                  <div
                    className="view__container-icon"
                    onClick={() => goSocialNetwork("Facebook")}
                  >
                    <IconView urlImage={IconFacebook} />
                  </div>
                )}
                {checkFields.instagram && (
                  <div
                    className="view__container-icon"
                    onClick={() => goSocialNetwork("Instagram")}
                  >
                    <IconView urlImage={IconInstagram} />
                  </div>
                )}
                {checkFields.tiktok && (
                  <div
                    className="view__container-icon"
                    onClick={() => goSocialNetwork("LinkedIn")}
                  >
                    <IconView urlImage={IconLinkedIn} />
                  </div>
                )}
                </div>
                {/* <div className="view__container-button-phone">
                  {checkFields.buttonSOS &&(
                     <div className="view__div-phone">
                     <div className="view__title-button-emergency">
                       En caso de emergencia comunícate aquí
                     </div>
                     <div
                       className="view__div-button-SOS"
                       onClick={goButtonsEmergency}
                     >
                       <div className="view__button-SOS">
                         <div>
                           <svg
                             xmlns="http://www.w3.org/2000/svg"
                             fill="currentColor"
                             className="view__icon-emergency bi bi-broadcast"
                             viewBox="0 0 16 16"
                             color="#fff"
                           >
                             <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                           </svg>
                         </div>
                         <label className="view__text-SOS">S.O.S</label>
                       </div>
                     </div>
                   </div>
                  )}
                </div> */}
              </div>
            ) : buttonSelected.button === 2 ? (
              <>
               <div className="view__container-menu">
                <div className="view__button-menu view__line-blue" onClick={() => changeButton(1)}>Ver información de contacto</div>
               </div>
               <InfoAdicional infoCode={infoCode} checkFields={checkFields} />
               
              </>
            ):(
              <>
              <div className="view__container-menu">
                <div className="view__button-menu view__line-blue" onClick={() => changeButton(1)}>Ver información de contacto</div>
               </div>
              <InfoVaccine infoCode={infoCode} checkFields={checkFields} />
              </>
            )}
          </div>
          <div>
            {/* <Footer infoCode={infoCode} checkFields={checkFields} /> */}
          </div>
          <Modal
            showModal={checkButtonSOS}
            setCheckButtonSOS={setCheckButtonSOS}
            goPhoneAditional={goPhoneAditional}
            goWhatAditional={goWhatAditional}
            infoCode={infoCode}
          />
        </div>
      )}
    </>
  );
};

function valueDefault() {
  return {
    name: "",
    lastName: "",
    phone: "",
    blood: "A+",
    diseases: "",
    allergies: "",
    company: "",
    address: "",
    country: "Colombia",
    city: "",
    instagram: "",
    facebook: "",
    tiktok: "",
    webSite: "",
    contactName: "",
    contactPhone: "",
    doctorName: "",
    doctorPhone: "",
    eps: "",
    prepaidMedicine: "",
    profession: "",
    linkPhoto: "",
    indicative: "+57",
    email: "",
    emailContact: "",
    nameVaccineCovid: "",
    linkPhotoVaccine: "",
  };
}

function checkFieldDefault() {
  return {
    diseases: true,
    allergies: true,
    company: true,
    EPS: true,
    prepaidMedicine: true,
    contactName: true,
    contactPhone: true,
    contactEmail: true,
    doctorName: true,
    doctorPhone: true,
    instagram: true,
    facebook: true,
    tiktok: true,
    webSite: true,
    vaccineCovid: true,
    photoVaccine: true,
    blood: true,
    buttonSOS:true,
    profession:true,
  };
}
