import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ScreenEdit } from '../screens/ScreenEdit';
import { ScreenIndex } from '../screens/ScreenIndex';
import { ScreenResponse } from '../screens/ScreenResponse';
import { ScreenView } from '../screens/ScreenView';
// import { FormCard } from '../screens/suscription/FormCard';

export const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/web'>
          <ScreenIndex />
        </Route>
        <Route exact path='/web/edit/:codeEdit'>
          <ScreenEdit />
        </Route>
        <Route exact path='/web/view/:codeView'>
          <ScreenView />
        </Route>
        <Route path='/web/response/edit/:type'>
          <ScreenResponse />
        </Route>
        {/* <Route path='/web/suscription/'>
          <FormCard />
        </Route> */}
        <Redirect to='/'/>
      </Switch>
    </Router>
  );
};
