import React from 'react';
import PropTypes from 'prop-types';

export const Select = ({
  options,
  changeInput,
  name,
  value,
}) => {

  if (value === '') {
    const target = {
      name: 'blood',
      value: 'A+',
    };
    changeInput({ target });
  }

  return (
    <select
      className="input__select"
      // defaultValue={"DEFAULT"}
      onChange={changeInput}
      name={name}
      value={value !== '' ? value : 'A+'}
    >

      {options &&
        options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
    </select>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  changeInput: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
