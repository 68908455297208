export const validateOptionalFields = async (
  optionalInputs,
  checkFieldDefault
) => {
  let arrayField = checkFieldDefault();
  optionalInputs.forEach((field) => {
    if (field === "diseases") {
      arrayField = {
        ...arrayField,
        diseases: false,
      };
    }
    if (field === "allergies") {
      arrayField = {
        ...arrayField,
        allergies: false,
      };
    }
    if (field === "company") {
      arrayField = {
        ...arrayField,
        company: false,
      };
    }
    if (field === "EPS") {
      arrayField = {
        ...arrayField,
        EPS: false,
      };
    }
    if (field === "prepaidMedicine") {
      arrayField = {
        ...arrayField,
        prepaidMedicine: false,
      };
    }
    if (field === "contactName") {
      arrayField = {
        ...arrayField,
        contactName: false,
      };
    }
    if (field === "contactPhone") {
      arrayField = {
        ...arrayField,
        contactPhone: false,
      };
    }
    if (field === "contactEmail") {
      arrayField = {
        ...arrayField,
        contactEmail: false,
      };
    }
    if (field === "doctorName") {
      arrayField = {
        ...arrayField,
        doctorName: false,
      };
    }
    if (field === "doctorPhone") {
      arrayField = {
        ...arrayField,
        doctorPhone: false,
      };
    }
    if (field === "instagram") {
      arrayField = {
        ...arrayField,
        instagram: false,
      };
    }
    if (field === "facebook") {
      arrayField = {
        ...arrayField,
        facebook: false,
      };
    }
    if (field === "tiktok") {
      arrayField = {
        ...arrayField,
        tiktok: false,
      };
    }
    if (field === "webSite") {
      arrayField = {
        ...arrayField,
        webSite: false,
      };
    }
    if (field === "vaccineCovid") {
      arrayField = {
        ...arrayField,
        vaccineCovid: false,
      };
    }
    if (field === "photoVaccine") {
      arrayField = {
        ...arrayField,
        photoVaccine: false,
      };
    }
    if (field === "blood") {
      arrayField = {
        ...arrayField,
        blood: false,
      };
    }
    if (field === "buttonSOS") {
      arrayField = {
        ...arrayField,
        buttonSOS: false,
      };
    }
    if (field === "profession") {
      arrayField = {
        ...arrayField,
        profession: false,
      };
    }
  });

  return arrayField;
};
