import firebase from './conection';

export const savePhotoBD = async(id, file, fileName, pathStorage) =>{


    let urlImage='';
    let result=false;

    const url=`${pathStorage}/${id}/${fileName}`;

    const storageRef = await firebase.storage().ref(url);
    await storageRef.put(file).then(()=>{
        result=true;
    })
    .catch((e)=>{
        
    })
    if(result){
        await storageRef.getDownloadURL().then((response)=>{
            urlImage=response;
        })
        .catch((e)=>{
           
        });
    }
    return urlImage;

}