import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal as ModalB } from "react-bootstrap";
import IconWhatsapp from "../../assets/iconoWhat.png";
import IconPhone from "../../assets/phoneIcon.png";
import { IconView } from "../../components/icons/IconView";

export const Modal = ({ showModal = false, setCheckButtonSOS, goPhoneAditional, goWhatAditional, infoCode }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCheckButtonSOS(false);
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      <ModalB show={show} onHide={handleClose} centered>
        <ModalB.Header closeButton>
          <ModalB.Title>{infoCode.contactName ? infoCode.contactName : "Contacto adicional"}</ModalB.Title>
        </ModalB.Header>
        <ModalB.Body>
          <div className="view__buttons-aditional-contact">
            <div className="view__icon-phone" onClick={goPhoneAditional}>
              <IconView urlImage={IconPhone} />
            </div>
            <div className="view__icon-what" onClick={goWhatAditional}>
              <IconView urlImage={IconWhatsapp} />
            </div>
          </div>
        </ModalB.Body>
        <ModalB.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </ModalB.Footer>
      </ModalB>
    </>
  );
};
