import { urlProduction } from "../utils/constants";

export async function getInfoCountryCode() {
  let infoindicative = {};
  await fetch(`${urlProduction}/api/parameters/countryCode/`)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      infoindicative = response;
    });

  return infoindicative;
}

export async function getInfoBloodType() {
  let infoBlood = {};
  await fetch(`${urlProduction}/api/parameters/bloodType/`)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      infoBlood = response;
    });

  return infoBlood;
}

export async function addOptionalField(optionalFields, id) {

  let result = false;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(optionalFields),
  };

  await fetch(`${urlProduction}/api/parameters/addOptionalField?id=${id}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      result = response;
    });

  return result;
}

export async function getOptionalField(id){

  let optionalFields = {};

  await fetch(
    `${urlProduction}/api/parameters/getOptionalField/?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      optionalFields = response;
    });

    return optionalFields;

}
