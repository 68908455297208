import React from 'react';
import PropTypes from 'prop-types';

export const TextArea = ({ changeInput, name, value }) => {
  return (
    <textarea className="input__text-area input__input" name={name} onChange={changeInput} value={value} />
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  changeInput: PropTypes.func.isRequired,
};
