import { AppRouter } from './router/AppRouter';
import './styles/styles.scss';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
