import React from "react";

export const ItemInformation = ({
  label="",
  data,
  classInformation,
  link = false,
  classLabel="view__title-information"
}) => {
  return (
    <div className={classInformation}>
      {!link ? (
        <>
          <h5 className={classLabel}>{label+":"}</h5>
          <div className="view__text-information">{data}</div>
        </>
      ) : (
        <>
          <h5 className="view__title-information">{label+": "}</h5>
          <a className="view__text-information" href={`https://${data}`}>
            {data}
          </a>
        </>
      )}
    </div>
  );
};
